<div class="pb-16">
    <!-- Header Section -->
    <header>
      <app-header-view [title]="'I nostri eventi'"></app-header-view>
    </header>
  
    <!-- Description Section -->
    <section>
      <p class="description-text pb-8 text-justify leading-relaxed px-4 xl:px-0">
        <span class="font-medium text-xl block mb-2">
          Storie di design a Vicenza:
        </span>
        <span class="block mb-4">
          Quando il design unisce una città. Nella splendida cornice di Piazza dei Signori abbiamo organizzato
          <strong>"STORIE DI DESIGN A VICENZA"</strong>, una settimana dedicata alla cultura del design.
        </span>
        <span class="block mb-4">
          Dal 12 al 18 novembre 2018 la nostra splendida città è diventata centro del design con mostre, conferenze e una rassegna
          sui grandi maestri del '900 italiano come Vico Magistretti e Gio Ponti per arrivare fino ai giorni nostri rappresentati da
          nomi internazionali come Palomba Serafini Associati e Vincent Van Duysen.
        </span>
        <span class="block mb-4">
          Protagonisti della serie di eventi sono state aziende leader del settore come Poltrona Frau, Vitra, Molteni, Dada, Flou che
          hanno allestito dei veri e propri atelier in Basilica Palladiana, Palazzo Cordellina e Loggia del Capitaniato. Non potevano
          essere scelte location migliori per condurre lo spettatore ad una approfondita conoscenza dell’arte e del design e dei sui
          rappresentanti migliori.
        </span>
        <span class="block">
          Un’atmosfera di poesia, dove il palcoscenico era parte integrante della magia. Un percorso che ha tenuto alta l’attenzione
          su un argomento così importante come il design, imprescindibile per chi come noi, vuole arredare con ricercatezza e mai
          volgare.
        </span>
      </p>
    </section>
  
    <!-- Video Section -->
    <div class="aspect-w-16 aspect-h-9 lg:w-full mx-auto">
      <iframe
        class="w-full h-full"
        src="https://www.youtube.com/embed/ElsM4xZ7MGc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </div>
  