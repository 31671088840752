<div class="">
  <!-- Header View -->
  <div class="w-10/12 mx-auto">
    <app-header-view 
      [title]="'Progetti di Classe: design e qualitá'" 
      [description]="'Esplora il design con progetti unici, creati con i migliori brand per unire stile, qualità e innovazione in ogni ambiente.'">
    </app-header-view>
  </div>

  <!-- Cards Section -->
  <div class="grid grid-cols-1 gap-8">
    <!-- Dynamic rendering for each card -->
    <div
      *ngFor="let item of itemsArray; let i = index"
      class="flex gap-4 items-stretch lg:flex-col"
      [ngClass]="{ 'xl:flex-row-reverse': i % 2 !== 0 }"
    >
      <!-- Text Div -->
      <div
      [ngClass]="{
        'ml-4': i % 2 === 0, 
        'mr-4': i % 2 !== 0
      }"
        class="bg-gray-100 lg:bg-white flex flex-col justify-between p-6 lg:p-0 w-full lg:w-full xl:w-1/3 lg:ml-0 lg:mr-0"
      >
        <div class="lg:w-11/12 lg:mx-auto">
          <h2 class="text-4xl font-medium mb-4 lg:text-center">{{ item.title }}</h2>
          <p class="text-gray-600 font-normal lg:text-center leading-loose">{{ item.description }}</p>
        </div>
      </div>

      <!-- Image Div -->
      <div
        class="h-96 overflow-hidden w-full lg:w-full xl:flex-1"
      >
        <img
          [src]="item.image"
          alt="{{ item.title }}"
          class="w-full h-full object-cover lg:rounded-none"
        />
      </div>
    </div>
  </div>
</div>
